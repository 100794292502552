import dynamic from 'next/dynamic';
import Head from 'next/head';
import { useRouter } from 'next/router';
import PropTypes from 'prop-types';
import { useCallback, useEffect, useRef } from 'react';

import ErrorBoundary from 'components/ErrorBoundary';

import useMediaQuery from 'hooks/useMediaQuery';
import useOffersFilters from 'hooks/useOffersFilters';
import useOffersViewModeQuery from 'hooks/useOffersViewModeQuery';
import useQueryClient from 'hooks/useQueryClient';

import {
  isInternationalOfferByOfferTags,
  isOfferCategoryIdFilterActive,
  isOfferSubcategoryIdFilterActive,
} from 'lib/offer';
import { offerQueryKeys } from 'lib/queryKeys';
import { removeHashFromUrl, stripQs } from 'lib/request';
import { scrollToElement } from 'lib/utils';

import { useGlobalQueries } from 'providers/GlobalQueriesProvider';

import OffersList from './OffersList';
import OffersListHeader from './OffersListHeader';

import OFFER from 'constants/offer';

const ErrorBoundaryFallback = dynamic(() => import('./ErrorBoundaryFallback'), {
  ssr: false,
});

const ADDITIONAL_SPACE_FROM_TOP_ON_SCROLL_IN_PX = 16;
const MILLISECONDS_TO_WAIT_BEFORE_SCROLLING_TO_LAST_OFFERS = 500;

export const OffersListSection = ({
  cardsAds,
  cookieIsUserLogged,
  cookieOffersViewMode,
  gaEventCategory,
  serverOffers,
}) => {
  const offersListHeaderRef = useRef(null);
  const lastOfferIdRef = useRef(serverOffers.after || null);
  const router = useRouter();
  const { isLg } = useMediaQuery();
  const queryClient = useQueryClient();
  const { activeOffersListTab } = useGlobalQueries();
  const { offersFiltersState, getOffersFilters } = useOffersFilters();

  const [offersViewMode, setOffersViewMode] = useOffersViewModeQuery({
    ssrIsUserLogged: cookieIsUserLogged,
    initialData: !cookieIsUserLogged
      ? OFFER.VIEW_MODES.GRID
      : cookieOffersViewMode,
  });

  /**
   * Scroll to last offers section of the page if a valid hash is present
   * at the url
   */
  useEffect(() => {
    const interval = setInterval(() => {
      if (window.location.hash.includes(OFFER.LAST_OFFERS_HASH)) {
        scrollToElement(
          offersListHeaderRef.current?.offsetTop -
            ADDITIONAL_SPACE_FROM_TOP_ON_SCROLL_IN_PX,
          isLg
        );
        removeHashFromUrl(router);
      }
    }, MILLISECONDS_TO_WAIT_BEFORE_SCROLLING_TO_LAST_OFFERS);

    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    const refetchOfferPagesWithFilters = async () => {
      const {
        categories,
        subcategories,
        lastAction,
        isInternationalOffersActive,
      } = getOffersFilters();

      const isOfferWithFilterActive = ({
        categoryId,
        subcategoryId,
        offerTags,
      }) =>
        isOfferCategoryIdFilterActive(categories, categoryId) ||
        isOfferSubcategoryIdFilterActive(subcategories, subcategoryId) ||
        (isInternationalOfferByOfferTags(offerTags) &&
          !isInternationalOffersActive);

      // TODO this can be improved with a future refactor
      if (lastAction === 'ADD' || !lastAction) {
        queryClient.setInfiniteQueryData(
          offerQueryKeys.list({ activeTab: activeOffersListTab.id }),
          (pages) =>
            pages.map((page) => ({
              ...page,
              offers: page.offers.filter(
                (offer) => !isOfferWithFilterActive(offer)
              ),
            }))
        );
        return;
      }

      if (lastAction === 'DELETE') {
        queryClient.refetchQueries(
          offerQueryKeys.list({ activeTab: activeOffersListTab.id })
        );
      }
    };

    refetchOfferPagesWithFilters();
  }, [queryClient, offersFiltersState]);

  const onGetNextPageParam = useCallback((lastOffers) => {
    lastOfferIdRef.current = lastOffers?.after;
  }, []);

  return (
    <>
      {lastOfferIdRef.current && (
        <Head>
          <link
            key={`${stripQs(router.asPath)}-next`}
            href={`${stripQs(router.asPath)}?after=${lastOfferIdRef.current}`}
            rel="next"
          />
        </Head>
      )}
      <div className="col-span-4 flex-col md:col-span-12 xl:col-span-9">
        <section className="col-span-4 px-4 md:col-span-12 lg:px-0">
          <OffersListHeader
            ref={offersListHeaderRef}
            className="hidden lg:block"
            gaEventCategory={gaEventCategory}
            offersViewMode={offersViewMode}
            onOffersViewMode={setOffersViewMode}
          />
          <ErrorBoundary fallback={ErrorBoundaryFallback}>
            <OffersList
              cardsAds={cardsAds}
              gaEventCategory={gaEventCategory}
              offersFilters={offersFiltersState}
              offersListHeaderRef={offersListHeaderRef}
              offersViewMode={offersViewMode}
              serverOffers={serverOffers}
              onGetNextPageParam={onGetNextPageParam}
            />
          </ErrorBoundary>
        </section>
      </div>
    </>
  );
};

OffersListSection.propTypes = {
  cardsAds: PropTypes.arrayOf(
    PropTypes.shape({
      bgcolor: PropTypes.arrayOf(PropTypes.string),
      description: PropTypes.string.isRequired,
      id: PropTypes.number.isRequired,
      image: PropTypes.string.isRequired,
      isClosable: PropTypes.bool.isRequired,
      mobileImage: PropTypes.string.isRequired,
      position: PropTypes.number.isRequired,
      storeSlug: PropTypes.string,
      type: PropTypes.string.isRequired,
      url: PropTypes.string.isRequired,
    })
  ).isRequired,
  cookieIsUserLogged: PropTypes.bool.isRequired,
  cookieOffersViewMode: PropTypes.string,
  serverOffers: PropTypes.shape({
    after: PropTypes.string.isRequired,
    before: PropTypes.number,
    offers: PropTypes.arrayOf(
      PropTypes.shape({
        categoryId: PropTypes.number,
        categoryName: PropTypes.string,
        categorySlug: PropTypes.string,
        key: PropTypes.string.isRequired,
        offerComments: PropTypes.number.isRequired,
        offerId: PropTypes.number.isRequired,
        offerIsHighlight: PropTypes.bool.isRequired,
        ratings: PropTypes.shape().isRequired,
        offerOldPrice: PropTypes.number,
        offerPhoto: PropTypes.string.isRequired,
        offerPrice: PropTypes.number.isRequired,
        offerPriceType: PropTypes.string.isRequired,
        offerPublished: PropTypes.string.isRequired,
        offerSlug: PropTypes.string.isRequired,
        offerStatusName: PropTypes.string.isRequired,
        offerTags: PropTypes.arrayOf(
          PropTypes.shape({
            name: PropTypes.string.isRequired,
            type: PropTypes.string.isRequired,
          })
        ),
        offerTitle: PropTypes.string.isRequired,
        storeDomain: PropTypes.string.isRequired,
        storeId: PropTypes.number,
        storeImage: PropTypes.string,
        storeName: PropTypes.string,
        subcategoryId: PropTypes.number,
        subcategoryName: PropTypes.string,
        subcategorySlug: PropTypes.string,
        userId: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
          .isRequired,
        userName: PropTypes.string.isRequired,
        userPhoto: PropTypes.string.isRequired,
        userTypeName: PropTypes.string.isRequired,
        userUsername: PropTypes.string.isRequired,
      })
    ),
  }).isRequired,
};

export default OffersListSection;
