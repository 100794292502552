import PropTypes from 'prop-types';
import { memo, useRef } from 'react';

import Icon from 'shopper/components/Icon';
import Link from 'shopper/components/Link';
import Text from 'shopper/components/Text';

import Sidebar from 'components/Sidebar';
import SidebarGoogleAd from 'components/Sidebar/GoogleAd';
import SidebarLatestCoupons from 'components/Sidebar/LatestCoupons';
import SidebarSocialMedia from 'components/Sidebar/SocialMedia';

import placeholder from 'lib/placeholder';
import { noop } from 'lib/utils';

import SidebarAd from './SidebarAd';

const HomeSidebar = ({
  gaEventCategory,
  sidebarAd = null,
  onShowFooterButtonClick = noop,
}) => {
  const sidebarGoogleAdRef = useRef(null);
  const latestCouponRef = useRef(null);
  const sidebarAdRef = useRef(null);

  return (
    <Sidebar
      className="hidden gap-y-4 md:col-span-3 xl:flex"
      refsToBeIgnoredBeforeFixing={[
        sidebarGoogleAdRef,
        latestCouponRef,
        sidebarAdRef,
      ]}
    >
      <SidebarGoogleAd
        ref={sidebarGoogleAdRef}
        adId="home_page_sidebar_1_300x250"
        adPath="/promobit.com.br_home_page/sidebar_1_300x250"
      />
      <SidebarLatestCoupons ref={latestCouponRef} />
      {sidebarAd && (
        <SidebarAd
          ref={sidebarAdRef}
          gaEventCategory={gaEventCategory}
          {...sidebarAd}
        />
      )}
      <SidebarSocialMedia />
      <SidebarGoogleAd
        adId="home_page_sidebar_2_300x250"
        adPath="/promobit.com.br_home_page/sidebar_2_300x250"
      />
      <div className="flex w-full justify-center">
        <Link type="secondary" onClick={onShowFooterButtonClick}>
          <Text className="text-neutral-high-500" size="size2">
            {placeholder('actions.seeFooter')}
          </Text>
          <Icon
            className="text-neutral-high-500"
            name="keyboard-arrow-down"
            size="size2"
          />
        </Link>
      </div>
    </Sidebar>
  );
};

HomeSidebar.propTypes = {
  gaEventCategory: PropTypes.string.isRequired,
  sidebarAd: PropTypes.shape({
    description: PropTypes.string.isRequired,
    image: PropTypes.string.isRequired,
    url: PropTypes.string.isRequired,
  }),
  onShowFooterButtonClick: PropTypes.func,
};

export default memo(HomeSidebar);
