import PropTypes from 'prop-types';
import { forwardRef } from 'react';

import Image from 'components/Image';

import { sendEvent } from 'lib/gtag';

import APP from 'constants/app';

const SidebarAd = forwardRef(
  ({ description, gaEventCategory, image, url }, ref) => (
    <a
      ref={ref}
      className="block size-full"
      href={url}
      rel="noopener noreferrer"
      target="_blank"
      onClick={() => {
        sendEvent({
          action: 'arroba_banner__click',
          category: gaEventCategory,
        });
      }}
    >
      <Image
        alt={description}
        className="size-full"
        height={304}
        sizes="(max-width: 1024px) 1px, 1280px"
        src={image}
        srcSet={`${APP.LOCAL_IMAGE_PATH}/general/placeholder.jpg 1w, ${image} 300w`}
        width={304}
      />
    </a>
  )
);

SidebarAd.displayName = 'SidebarAd';

SidebarAd.propTypes = {
  description: PropTypes.string.isRequired,
  gaEventCategory: PropTypes.string.isRequired,
  image: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
};

export default SidebarAd;
