/**
 * TODO
 * - in some cases the scroll-y don't start with `0`. You can view this issue
 * in `OffersCarousel` of `ArticlePage`
 * - resolve minor style issues when the viewport is similar to a tablet
 * - overflow occurring in `SuggestNewOfferForm`
 */
import dynamic from 'next/dynamic';
import PropTypes from 'prop-types';
import { useCallback, useMemo, useRef, useState } from 'react';
import { twJoin, twMerge } from 'tailwind-merge';

import Box from 'shopper/components/Box';

import useMediaQuery from 'hooks/useMediaQuery';

import ButtonArrow from './ButtonArrow';

const Dots = dynamic(() => import('./Dots'));

const X_AXIS_SCROLL_DESKTOP_MULTIPLIER = 0.5;
const X_AXIS_SCROLL_MOBILE_MULTIPLIER = 1;

const toCarouselItemWidth = ({ slidesPerView, itemSize }) =>
  slidesPerView === 1 ? `${itemSize}%` : `calc(${itemSize}%/${slidesPerView})`;

const CarouselBox = ({
  arrowsProps = {},
  dots = false,
  carouselProps,
  className,
  full = false,
  itemProps,
  list,
  render,
  showArrows,
  ...rest
}) => {
  const [showPrevArrow, setShowPrevArrow] = useState(false);
  const [endOfScroll, setEndOfScroll] = useState(false);
  const carouselRef = useRef(null);
  const { isSm, isMd, isLg, isXl } = useMediaQuery();
  const { breakpoints, itemSize, slidesPerView, snapMode } = itemProps ?? {};

  const carouselItemsStyles = useMemo(
    () => ({
      '--itemWidth': toCarouselItemWidth({ slidesPerView, itemSize }),
      '--itemWidthMD': toCarouselItemWidth({
        slidesPerView: breakpoints?.md?.slidesPerView || slidesPerView,
        itemSize: breakpoints?.md?.itemSize || itemSize,
      }),
      '--itemWidthLG': toCarouselItemWidth({
        slidesPerView:
          breakpoints?.lg?.slidesPerView ||
          breakpoints?.md?.slidesPerView ||
          slidesPerView,
        itemSize:
          breakpoints?.lg?.itemSize || breakpoints?.md?.itemSize || itemSize,
      }),
      '--itemWidthXL': toCarouselItemWidth({
        slidesPerView:
          breakpoints?.xl?.slidesPerView ||
          breakpoints?.lg?.slidesPerView ||
          breakpoints?.md?.slidesPerView ||
          slidesPerView,
        itemSize:
          breakpoints?.xl?.itemSize ||
          breakpoints?.lg?.itemSize ||
          breakpoints?.md?.itemSize ||
          itemSize,
      }),
    }),
    []
  );

  const onCarouselScroll = useCallback(() => {
    const { scrollWidth, scrollLeft, clientWidth } = carouselRef.current;
    const limitDiff = 16;
    const isEndOfScroll = scrollLeft + clientWidth + limitDiff >= scrollWidth;
    const halfToShowPrevArrow = 2;

    setEndOfScroll(isEndOfScroll);

    if (scrollLeft === 0) {
      setShowPrevArrow(false);
      return;
    }

    if (!isMd) {
      setShowPrevArrow(scrollLeft >= clientWidth);
      return;
    }

    setShowPrevArrow(
      scrollLeft + clientWidth + limitDiff >= scrollWidth / halfToShowPrevArrow
    );
  }, [isMd]);

  const onButtonArrowClick = useCallback((multiplier) => {
    const carouselElem = carouselRef.current;
    const scrollDistance = carouselElem.clientWidth * multiplier;

    carouselElem.scrollBy({ left: scrollDistance, behavior: 'smooth' });
  }, []);

  const isItemsWithFullWidth = () => {
    const mediaQueries = [isSm, isMd, isLg, isXl];
    const currMediaQueryIndex = mediaQueries.lastIndexOf(true);
    const slidesCount = carouselRef.current?.childElementCount;
    const slidesPerViewArr = [
      slidesPerView,
      breakpoints?.md?.slidesPerView,
      breakpoints?.lg?.slidesPerView,
      breakpoints?.xl?.slidesPerView,
    ];

    if (slidesPerViewArr[currMediaQueryIndex] !== undefined) {
      return slidesPerViewArr[currMediaQueryIndex] === slidesCount;
    }

    for (let i = currMediaQueryIndex; i >= 0; i--) {
      if (slidesPerViewArr[i] !== undefined) {
        return slidesPerViewArr[i] === slidesCount;
      }
    }
  };

  const xAxisScrollMultiplier = isMd
    ? X_AXIS_SCROLL_DESKTOP_MULTIPLIER
    : X_AXIS_SCROLL_MOBILE_MULTIPLIER;

  return (
    <Box
      className={twMerge(
        'relative bg-transparent dark:bg-transparent',
        className
      )}
      full
      {...rest}
    >
      <div
        ref={carouselRef}
        className={twMerge(
          'mx-auto my-0 flex snap-x snap-mandatory gap-x-2 overflow-y-hidden overflow-x-scroll scroll-smooth scrollbar-none',
          carouselProps?.className,
          full && 'w-full'
        )}
        onScroll={showArrows ? onCarouselScroll : null}
      >
        {list.map((item, index, array) => (
          <div
            key={`slide-${index}`}
            className={twJoin(
              'w-[var(--itemWidth)] flex-shrink-0 md:w-[var(--itemWidthMD)] lg:w-[var(--itemWidthLG)] xl:w-[var(--itemWidthXL)]',
              snapMode,
              isItemsWithFullWidth() && 'flex-1'
            )}
            style={carouselItemsStyles}
          >
            {render(item, index, array)}
          </div>
        ))}
      </div>
      {showArrows && showPrevArrow && (
        <ButtonArrow
          direction="left"
          onClick={() => onButtonArrowClick(-xAxisScrollMultiplier)}
          {...arrowsProps}
        />
      )}
      {showArrows && !endOfScroll && (
        <ButtonArrow
          direction="right"
          onClick={() => onButtonArrowClick(xAxisScrollMultiplier)}
          {...arrowsProps}
        />
      )}
      {dots && (
        <Dots
          actualPage={2}
          carouselRef={carouselRef}
          className="mt-2"
          totalOfPages={5}
        />
      )}
    </Box>
  );
};

CarouselBox.propTypes = {
  arrowsProps: PropTypes.shape({
    display: PropTypes.array,
    offsetX: PropTypes.shape(),
  }),
  dots: PropTypes.bool,
  full: PropTypes.bool,
  itemProps: PropTypes.shape({
    breakpoints: PropTypes.shape({
      md: PropTypes.shape({
        itemSize: PropTypes.number,
        snapMode: PropTypes.string,
        slidersPerView: PropTypes.number,
      }),
      lg: PropTypes.shape({
        itemSize: PropTypes.number,
        snapMode: PropTypes.string,
        slidersPerView: PropTypes.number,
      }),
      xl: PropTypes.shape({
        itemSize: PropTypes.number,
        snapMode: PropTypes.string,
        slidersPerView: PropTypes.number,
      }),
    }),
    itemSize: PropTypes.number,
    slidesPerView: PropTypes.number,
    snapMode: PropTypes.string,
  }).isRequired,
  list: PropTypes.arrayOf(
    PropTypes.oneOfType([PropTypes.shape(), PropTypes.array])
  ).isRequired,
  render: PropTypes.func.isRequired,
};

export default CarouselBox;
